
import {
  Component, Prop, Vue, Watch
} from 'vue-property-decorator';
import TypeJpDate from '@/model/type/TypeJpDate';
import TypeJpEra, { getJpEra } from '@/model/type/TypeJpEra';
import SelectEra from '@/components/Parts/SelectEra.vue';
import SelectYear from '@/components/Parts/SelectYear.vue';
import SelectMonth from '@/components/Parts/SelectMonth.vue';
import SelectDay from '@/components/Parts/SelectDay.vue';

@Component({
  components: {
    SelectEra,
    SelectYear,
    SelectMonth,
    SelectDay,
  }
})
export default class InputJpDate extends Vue {
  @Prop({ default: () => new TypeJpDate() }) value!: TypeJpDate;
  @Prop({ default: true }) showInvalid!: boolean;
  @Prop({ default: true }) showDay!: boolean;
  @Prop({ default: true }) required!: boolean;
  @Prop({ default: false }) todaySelector!: boolean;
  @Prop({ default: false }) isReiwaOnly!: boolean;

  getEra(): TypeJpEra {
    return getJpEra(this.value.era);
  }

  setToday():void{
    const now = TypeJpDate.getToday();
    this.value.era = now.era;
    this.value.year = now.year;
    this.value.month = now.month;
    this.value.day = now.day;
  }

  @Watch('value')
  watchValue():void {
    this.$emit('input', this.value);
  }
  changeItem():void{
    this.$emit('changeItem');
  }

  isValid(): boolean {
    if (!this.required) {
      if (this.value.isEmpty()) {
        return true;
      }
    }
    const typeJpDate:TypeJpDate = TypeJpDate.clone(this.value);
    return typeJpDate.isValid;
  }
}
