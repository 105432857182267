
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import TypeJpEra from '@/model/type/TypeJpEra';

@Component({
})
export default class SelectYear extends Vue {
  innerValue = 1;
  @Prop({ default: 1 }) value!: number;
  @Prop() maxYears!: number;
  @Prop({ default: true }) required!: boolean;

  mounted(): void {
    this.innerValue = this.value;
  }

  @Watch('value')
  watchParam(): void {
    this.innerValue = this.value;
  }

  @Watch('innerValue')
  watch():void {
    this.$emit('input', this.innerValue);
  }

  @Watch('value')
  changeItem():void {
    this.$emit('changeItem');
  }
}
