
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';

@Component({
})
export default class SelectEra extends Vue {
  innerValue = '';
  @Prop({ default: 'Reiwa' }) value!: string;
  @Prop({ default: true }) required!: boolean;
  @Prop({ default: false }) isReiwaOnly!: boolean;

  mounted(): void {
    this.innerValue = this.value;
  }

  @Watch('value')
  watchParam(): void {
    this.innerValue = this.value;
  }

  @Watch('innerValue')
  watch():void {
    this.$emit('input', this.innerValue);
  }

  @Watch('value')
  changeItem():void {
    this.$emit('changeItem');
  }
}
