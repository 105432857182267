
import { Component, Vue, Prop } from 'vue-property-decorator';
import DownloadIcon from '@/svg/DownloadIcon.vue';

@Component({
  components: {
    DownloadIcon
  }
})
export default class Help extends Vue {
  tooltipActive = false;
  @Prop() helpText!: string;
  @Prop({ default: false }) isHeightAuto!: boolean;

  showText():string {
    return this.helpText;
  }
}
