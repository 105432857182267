
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FileUpload extends Vue {
  @Prop() value?: File|null;
  isOver = false;

  uploadFile(e:Event):void {
    const target = e.target as HTMLInputElement;
    if (target.files && target.files?.length > 0) {
      this.setFile(target.files[0]);
    }
  }
  dropFile(e: DragEvent):void {
    this.isOver = false;
    const transfer = e.dataTransfer;
    this.setFile(transfer?.files[0]);
  }
  setFile(file: File|undefined):void {
    if (file) {
      this.$emit('input', file);
      // this.$nextTick();
      this.$emit('fileUpload', file);
      this.$emit('licensefileUpload', file, file.name);
    }
  }
}
